<!--能人详情-->
<template>
    <div class="bestPerson">
        <div class="breadcrumb w-1400">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/xiangningqing'}">乡宁情</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/bestperson'}">能人列表</el-breadcrumb-item>
                <el-breadcrumb-item >能人详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="p-list w-1400">
            <div class="floor mt-20">
                <div class="floor-h3"><i class="iconfont icon-fuzhushuxian"></i> 基本信息</div>
                <div class="floor-content mt-20 clearfix">
                    <div class="f1-pic fl">
                        <el-image style="width:100%;heihgt:100%" fit="cover" :src="require('../../../../public/img/xnq/p-detail1.png')"></el-image>
                    </div>
                    <div class="floor-text floor1 fl">
                                王宏，男，藏族，中共党员，1965年5月出生，青海称多人，中小
                        学特级教师，他身残志坚，教书育人，以校为家，不改初心。扎根高原藏
                        区，以心换心，以爱育爱，唤醒孩子的心灵。“三十年弦歌不辍”，始终
                        在平均海拔4500米、年平均气温在零下1.6摄氏度的雪域高原用热爱与传
                        承书写师道。他倾心民族教育，是“公认的教学能手”、“教师充电的宝
                        藏”、“思想永远跑得更快的学习型教师”；他悉心照顾学生学习生活，
                        守护学生求学之路，与学生情同父子，曾获全国教书育人楷模、第二届四
                        有好老师教师启功奖、青海省最美教师、最美青海人提名奖、玉树好人、
                        玉树州创建民族团结先进个人等荣誉称号。
                    </div>
                    
                </div>
            </div>
            <div class="floor">
                <div class="floor-h3"><i class="iconfont icon-fuzhushuxian"></i> 经验技术</div>
                <div class=" clearfix mt-20">
                    <div class="f2-pic fl">
                        <el-image style="width:100%;heihgt:100%" fit="cover" :src="require('../../../../public/img/xnq/jingyan1.png')"></el-image>
                    </div>
                    <div class="f2-r-text fl floor-text">
                        <div class="f2-inner">
                        <span class="c-06">师德表现：</span> 思想上，不断学习先进的教育教学理论，更新教育思想，提高自己的
                        思想意识。“学高为师，品高为范”，所以我以自己的良好的人格魅力，以身作
                        则，给学生无形的力量与教育。积极参加教职工大会及组内教研活动。每周按时
                        参加升旗仪式，从不缺勤。热爱集体，服从分配，团结同志，爱岗敬业，做到既
                        教书又育人。业余时间不从事有偿家教及第二职业。

                        </div>
                    </div>                          
                </div>
                <div class=" clearfix ">
                    <div class="f2-r-text fl floor-text">
                        <div class="f2-inner">
                        <span class="c-06">教学情况：</span> 教学情况：1、备课深入细致。每次都认真研究教材，多方参阅各种资料，力求
                            深入理解教材，准确把握难点、重点。在制定教学目的时，非常注意学生的实际
                            情况，注意因材施教。在教学中注意弱化难点，强调重点。规范书写教案，从复
                            习提问、新课引入到讲授都精心设计。并不断归纳总结经验教训。
                                        2、注重课堂教学效果。以启发式教学为主，坚持“学生为主体，教
                            师为主导”的教学思路，注重讲练结合，并将一些开放性的教学问题引入课堂，
                            拓展学生思维。
                                        3、认真及时批改作业，通过作业及时了解学生的学习情况，并认真
                            总结每次考试，在辅导中做到有的放矢。
                            。

                        </div>
                    </div>
                    <div class="f2-pic fl">
                        <el-image style="width:100%;heihgt:100%" fit="cover" :src="require('../../../../public/img/xnq/jingyan1.png')"></el-image>
                    </div>                          
                </div>
                <div class="floor-text">
                        <span class="c-06">设想与打算：</span>虚心向其他老师学习，在教学上做到有疑必问。在每个章节的学习上都积极征求其他有经验老师的意见，学习他们的方法。同时多听老教
                        师的课，做到边听边学，给自己不断充电，弥补自己在教学上的不足，并常请备课组长和其他教师来听课，征求他们的意见，改进教学工作。
                    </div>
            </div>
            <div class="floor">
                <div class="floor-h3"><i class="iconfont icon-fuzhushuxian"></i> 能人事迹</div>
                <div class="floor-h4">
                    三十年弦歌不辍，他的臂膀，护卫着一代代草原雏鹰，在高原起飞，在雪域翱翔。
                </div>
                <div>
                    <div class="f3-pic fl">
                        <el-image style="width:100%;heihgt:100%" fit="cover" :src="require('../../../../public/img/xnq/v1.png')"></el-image>
                    </div>   
                    <span class="floor-text">
                                                                                                                                                       作为一名教育工作者，王宏始终遵守《中小
                                                                                                                          学职业道德规范》，以教师职业道德标准严格自
                                                                                                                          律，为人师表要求规范自己言行，以教好书育好
                                                                                                                          人为天职，勇挑重担，默默奉献，把全部精力都
                                                                                                                          用在了学校工作上、教育教学上，勇于创新，大
                                                                                                                          胆尝试新课程理念，在教育教学中多次获奖。
                                                                                                                               从教以来，王宏始终严格要求自己，他常说，
                                                                                                                          作为教师要树立崇高的职业信念，把教书育人当
                                                                                                                          做自己伟大生命，要做一个正直的、有德行的、
                                                                                                                          有学识的、有爱心的老师，用自己的人格魅力、
                                                                                                                          学识魅力教育感染学生，当好学生健康成长的指
                                                                                                                          导者和引路人。
                                                                                                                               1988年，刚从玉树州民族师范学校 毕业的
                                                                                                                          王宏，并没有像其他同学那样选择留在玉树州府
                                                                                                                          或县城工作，而是毅然决然地回到家乡称多县，
                                                                                                                          并主动申请到离县城70多公里开外的歇武镇中心
                    寄宿制学校任教。因小儿麻痹症导致他左脚残疾，与正常人相比，残疾给王宏老师工作学习与生活中有诸多的不便。初到学校，他就承担了毕业班的
                    教学任务，并担任初二年级物理课教学和小学一年级班主任,三门主课教学，超课时工作，他面临着巨大压力。但身体上的残疾、外在环境的艰苦都抵
                    挡不住教学的热情与干劲。他不仅克服了各种困难，出色地完成了教学任务，而且在随后的教学工作中创造了一个又一个奇迹。
                        王宏常对人说：要想教好书、育好人就得多费心思，多花精力和时间，了解学生、关心学生、服务学生；所谓教书育人，其实就是教师要有渊
                    博的知识，还要有强大的人格魅力，要有爱心，用爱心和责任来做好工作。王宏是学校出了名的工作狂,一工作起来总是没日没夜，很少回家，就连
                    妻子分娩时，他都没时间去照顾，家里的一应事务全都落在妻子一个人身上。为此，家里人经常抱怨他是“学校的操心人，家里的甩手掌柜。”他
                    却总是淡淡一句“学生们不容易”后，依然“我行我素”。
                        2014年，称多县教育局号召教师去牧区边远村级学校开展送教支教活动，王宏不顾身体残疾，第一个报名参加，并亲自率队去条件最艰苦的
                    村小红旗小学送教,长期恶劣的高原环境，导致他患有肺病，肝功能严重受损，有时候彻夜难眠，手止不住发抖，校长和亲人都劝他去医院，他却说
                    “再坚持坚持”。
                        王宏是学校老师们公认的“学习型教师”，是教师团队中的领头雁和带路人。他积极参加教育科研活动，认真撰写科研论文，所撰论文《物理
                    实验课中如何培养学生的操作能力》曾发表于《教育教学科研》杂志上并荣获一等奖；《如何引导学生创造性思维能力》发表于《文苑》杂志，并
                    荣获一等奖；2013年学校在称多县学校党建工作考评中取得了第三名的好成绩 2017年学校在称多县学校党建工作考评中取得了第一名的好成绩 
                    2016年以来，《人民日报》刊登“扎根藏区，我特别自豪”，《光明日报》刊登“雪域雄鹰筑梦人”，《人民教育报》刊登“为爱坚守的藏乡园丁”
                    《青海日报》刊登“‘六公’分撑起的生命高度”宣传他的事迹。
                        他积极参加培训，不断充实自己的头脑，经常认真的总结、反思，把学到的先进理念运用于教育教学之中,勤奋的学习、不断的积累使他逐渐成
                    熟，他的课堂已经成了学生探究知识的乐园，成了同事们“取经”“充电”的宝藏。有些新老师刚开始从事教学工作摸不着头脑，不会备课、讲课，
                    王宏经常便一遍又一遍地给老师们讲解备课的方法，把自己的教学经验毫无保留地传授给其他老师，让大家取长补短，共同成长进步。几十年来，
                    王宏老师所带班级的教学成绩都名列前茅，受到上级部门的嘉奖和家长的一致好评。
                        从2012年开始，王宏担任学校党支部书记，为了使初中部加快信息化教育的步伐，提高教育教学质量，他从自己积蓄中拿出17万元为学校捐
                    赠修建了一个“多功能学术报告厅”，以提高学校教学设施，用于学校召开各类会议、学术讨论、演讲、报告、新闻发布、多媒体教学培训、课堂
                    录播等。
                        痴心一片终不悔，只为桃李竞相开。面对成绩和荣誉，王宏老师没有沾沾自喜，有的是更多的冷静与思考。作为一名教育工作者，他孜孜不倦
                    地投入教学，无微不至地关爱学生，始终把“学高为师，德高为范”铭记于心。他用满怀的工作热情诠释了对民族教育事业的无限热爱，用默默付
                    出表达了对教育事业的忠诚，用汗水浇铸师魂，用师爱播种希望，他愿做学生梦想起飞的翅膀。
                    </span>
                </div>
            </div>
            <div class="floor">
                <div class="floor-h3"><i class="iconfont icon-fuzhushuxian"></i> 奖项</div>
                <div class="f4-content">
                    <el-row :gutter="30">
                        <el-col :span="8" v-for="item in 6" :key="item + 'jx'">
                            <div class="f4-item">
                                <div class="f4-pic">
                                    <el-image style="width:100%;heihgt:100%" fit="cover" :src="require('../../../../public/img/xnq/jxiang.png')"></el-image>
                                </div>
                                <div class="f4-title">全国教书育人楷模</div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import thirdNav from '@/components/thirdNav'
export default {
    components:{
        thirdNav
    }
}
</script>
<style scoped>
.f4-title{
    font-size: 18px;
    text-align: center;
    line-height: 30px;
}
.f4-item{
    margin-top: 20px;
}
.f3-pic {
    width: 800px;
    height: 460px;
    padding: 0 20px;
}
.floor-h4{
    font-size: 22px;
    color: #2a81d3;
    padding: 20px
}
.c-06{
    color: #0e6ac1;
}
.f2-inner{
    display: table-cell;
    vertical-align: middle;
    padding: 30px;
}
.f2-r-text{
    width: calc(100% - 500px);
    height: 360px;
    display: table;
}
.f2-pic{
    width: 500px;
    height: 360px;
}
.floor-text.floor1{
    width:calc(100% - 340px);
    padding:30px;
}
.floor-text{
    font-size: 16px;
    text-indent: 30px;
    line-height: 30px;

}
.f1-pic{
    width: 285px;
    height: 340px;
}
.floor{
    /* border:1px solid #777; */
    padding: 20px;
}
.floor-h3 i{
    font-size: 22px;
    
}
.floor-h3{
    background: -webkit-gradient(linear, top,  bottom, from(#dcebff), to(#eaf3ff));
    background: linear-gradient(to bottom, #dcebff 0%, #eaf3ff 100%);
    line-height: 42px;
    font-size: 22px;
    color: #0e6ac1;
}
.bestPerson{
    font-family: '微软雅黑';
}
.breadcrumb >>> .el-breadcrumb .is-link,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner{
    font-size: 18px;
    font-family: '微软雅黑';
    color: #0e6ac1;
}
.breadcrumb{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #0e6ac1;
    margin-bottom: 50px;
}
</style>